#home {
  .link_parent_box {
    margin-bottom: 60px;
    width: 100%;

    .link_box {
      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc((100% / 4) - (24px * 3)), 1fr));
        column-gap: 24px;
      }

      li {
        border: solid 3px $primary-purple;
        border-radius: $container-radius;
        text-align: center;
        max-height: 150px;
        display: flex;
        padding: 0 30px;

        .left {
          margin: auto auto auto 0;

          .location {
            font-family: 'GTWalsheimPro-Bold';
            font-size: 16px;
            color: $primary-purple;
            text-align: left;
          }
        }

        .right {
          margin: 16px;

          .redirect {
            background-color: $primary-green;
            border-radius: 50%;
            font-family: 'GTWalsheimPro-Bold';
            font-size: 32px;
            color: white;

            width: 50px;
            height: 50px;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .table_list_box {
    .table_item_box {
      p {
        display: inline-block;
      }

      .title {
        font-family: 'GTWalsheimPro-Bold';
      }

      //homeDemo
      .btn {
        padding: 12px 15px;
        vertical-align: super;
        @include rtl-sass-prop(margin-left, margin-right, 46px);
      }

      .hideShowButton {
        @include rtl-sass-value(float, right, left);
      }

      .table_box {
        .hideAccountCheckbox /deep/ .el-checkbox__label {
          display: none;
        }

        .leverageTextLeft {
          width: 45%;
          @include rtl-sass-value(text-align, right, left);
        }

        .icon {
          display: inline-block;

          .paddingLeft {
            padding-left: 10px;
            @include rtl-sass-prop(padding-left, padding-right, 10px);
            img {
              width: 16px;
              margin-bottom: 5px;
            }
          }
        }

        .lock_icon {
          width: 32px;
        }

        .reset_balance {
          a {
            color: #e81212;
            font-size: 23px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  #home .link_parent_box .link_box ul {
    grid-template-columns: repeat(auto-fit, minmax(calc(48% - (24px * 3)), 1fr));
    row-gap: 20px;
  }
}

@media (max-width: 550px) {
  #home .link_parent_box {
    margin-bottom: 40px;

    .link_box ul {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      li .right .redirect {
        font-size: 16px;
        width: 30px;
        height: 30px;
      }
    }
  }

  #home .table_list_box .table_item_box {
    .title {
      margin-bottom: 20px;
    }

    .btn {
      display: table;
      @include rtl-sass-prop(margin-left, margin-right, 0);
      margin-bottom: 20px;
      line-height: 2;
    }
  }
}
