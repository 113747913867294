.closeButton {
  float: right;
  cursor: pointer;
  margin: 15px 15px 0 0;
  color: white;
  font-size: 15px;
}

/deep/ .el-dialog {
  .el-dialog__body {
    background-color: $primary-purple;
    padding: 0px;
    overflow-y: visible;
    max-height: 100vh;
  }
  .el-dialog__header {
    display: none;
  }
  max-width: 600px;
}

@media (max-width: 768px) {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      top: 0px;
    }
  }
}
