/* avtive 弹框样式开始 */
.active_dialog {
  /deep/ .el-dialog__body {
    text-align: center;

    p {
      font-weight: 200;
      line-height: 1.64;
      color: $primary-purple;
      word-break: break-word;
      margin-bottom: 20px;
    }
    p.highlighter {
      color: $red;
    }
  }
}

/* avtive 弹框样式结束 */
@media (max-width: 510px) {
  .active_dialog /deep/ .el-dialog__body {
    padding: 45px 20px 15px;
  }
}
@media (max-width: 450px) {
  .active_dialog /deep/ .el-dialog__body .el-button {
    margin-bottom: 20px;
    max-width: none;
    display: block;
  }
}
